export const checkoutFormModel = {
    formId: 'checkoutForm',
    formField: {
      firstName: {
        name: 'firstName',
        label: 'First name',
        requiredErrorMsg: 'First name is required'
      },
  
      lastName: {
        name: 'lastName',
        label: 'Last name',
        requiredErrorMsg: 'Last name is required'
      },

      email: {
        name: 'email',
        label: 'Email',
        requiredErrorMsg: 'Email is required'
      },

      company: {
        name: 'company',
        label: 'Company',
        requiredErrorMsg: 'Last name is required'
      },

      password: {
        name: 'password',
        label: 'Password',
        requiredErrorMsg: 'Password is required'
      },

      confirmPassword: {
        name: 'confirmPassword',
        label: 'Confirm Password',
        requiredErrorMsg: 'Password must match'
      },


      cdwCustomerID: {
        name: 'cdwCustomerID',
        label: 'CDW Customer ID',
        requiredErrorMsg: 'CDW Customer ID is required'
      },

      cdwTokenKey: {
        name: 'cdwTokenKey',
        label: 'CDW Token Key',
        requiredErrorMsg: 'CDW Token Key is required'
      },

      cdwTokenValue: {
        name: 'cdwTokenValue',
        label: 'CDW Token Value',
        requiredErrorMsg: 'CDW Token Value is required'
      },

      cdwBasicAuthToken: {
        name: 'cdwBasicAuthToken',
        label: 'CDW Basic Auth Token',
        requiredErrorMsg: 'CDW Basic Auth Token is required'
      },
 
    }
};