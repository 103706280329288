import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Badge, Box, useMediaQuery } from "@mui/material";
import IconButton from "components/@extended/IconButton";

import { ShoppingCartOutlined } from "@ant-design/icons";
import { setDrawerOpen, drawerOpenSelector, selectedProductCountSelector } from "pages/search/redux/selectedRowsSlice";

const Cart = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
  
  const dispatch = useDispatch();

  const productCount: number = useSelector(selectedProductCountSelector);
  const drawerOpen = useSelector(drawerOpenSelector);
  const anchorRef = useRef<any>(null);

  const handleToggle = () => {
    dispatch(setDrawerOpen(!drawerOpen));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        aria-label="open profile"
        ref={anchorRef}
        // aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={productCount} color="primary">
          <ShoppingCartOutlined />
        </Badge>
      </IconButton>
    </Box>
  );
};

export default Cart;
