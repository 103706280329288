import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest, logoutRequest, selectIsLoggedIn, selectIsInitialized, selectCurrentUser } from 'pages/auth/store/authSlice';
import { NavigateFunction } from 'react-router';
// import { selectIsLoggedIn, selectIsInitialized, selectCurrentUser } from '../store/selectors';

// auth provider
// import AuthContext from 'contexts/JWTContext';

interface LoginArgs {
  email: string;
  password: string;
  navigate: NavigateFunction
}

export const useAuth = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isInitialized = useSelector(selectIsInitialized);
  const user = useSelector(selectCurrentUser);

  const login = useCallback(({email, password, navigate }) => {
    dispatch(loginRequest({ email, password, navigate }));
  }, [dispatch]);

  const logout = useCallback(() => {
    dispatch(logoutRequest());
  }, [dispatch]);


  const register = useCallback((email: string, password: string, firstName: string, lastName: string) => {
    console.log('Registering user with email: ', {email, password, firstName, lastName});
  }, [dispatch]);

  const updateProfile = () => {};
  // Implement other auth-related operations as needed, e.g., register, resetPassword

  return {
    isLoggedIn,
    isInitialized,
    user,
    login,
    logout,
    register,
    updateProfile,
    // Include other operations here
  };
};



export default useAuth;
