// third-party
import { combineReducers } from 'redux';
import selectedRowsReducer, { cdwProductsEntitySlice } from 'pages/search/redux/selectedRowsSlice';
import { authSlice, authPageSlice } from 'pages/auth/store/authSlice';
import snackbar from './snackbar';

import menu from './menu';

const pages = combineReducers({
  cdwPage: selectedRowsReducer,
  authPage: authPageSlice.reducer
});

const entities = combineReducers({
  cdwProducts: cdwProductsEntitySlice.reducer,
})

const reducers = combineReducers({
  menu,
  snackbar,
  pages,
  entities,
  auth: authSlice.reducer,
});


export default reducers;
