import React, { useState, useMemo, useCallback, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ColumnDef, Row } from "@tanstack/react-table";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Stack,
  Avatar,
  Checkbox,
} from "@mui/material";
import DataGrid from "components/DataGrid";

import {
  CSVExport,
  DebouncedInput,
  HeaderSort,
  IndeterminateCheckbox,
  RowSelection,
  SelectColumnSorting,
  TablePagination,
} from "components/third-party/react-table";

import {
  selectedRowsSelector,
  updateQuery,
  querySelector,
  formattedProductsSelector,
  isSearchPageLoadingSelector,
  toggleRowSelection,
} from "./redux/selectedRowsSlice";

import MainCard from "../../components/MainCard";
import SelectedProductsDrawer from "./sections/SelectedProductsDrawer";
import { Product } from "types/products";


const SearchPage = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  // const [mode, setMode] = useState<"pagination" | "virtualization">(
  //   "virtualization"
  // );

  const selectedProducts = useSelector(selectedRowsSelector);
  const query = useSelector(querySelector);
  const products = useSelector(formattedProductsSelector);
  const isLoading = useSelector(isSearchPageLoadingSelector);
  const dispatch = useDispatch();

  console.log("__SearchPage__", { isLoading, selectedProducts });

  const searchProducts = () => {
    setPage(0);
    dispatch({ type: "SEARCH_CDW_PRODUCTS", payload: { query, page: 1 } });
  };

  const handleFetchMoreData = () => {
    const pageNum = page + 1;
    console.log("handleFetchMoreData", { pageNum });
    dispatch({ type: "GET_CDW_PRODUCTS", payload: { query, page: pageNum } });
    setPage(page + 1);
  };

  const handleSelectProduct = (row: Row<Product>) => {
    const product = row.original;
    const productCode = product.productCode ?? "";
    const addProduct = selectedProducts.includes(productCode)

    dispatch(toggleRowSelection({product, toggle: addProduct}))
  };

  const handleChangeQuery = (value: string) => {
    dispatch(updateQuery({ query: value }));
  };

  const handleChangePage = useCallback((newPage: number) => {
    setPage(newPage);
    console.log("handleChangePage", newPage);
    dispatch({
      type: "GET_CDW_PRODUCTS",
      payload: { query, page: newPage + 1 },
    });
  }, [query, page]);

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    dispatch({ type: "GET_CDW_PRODUCTS", payload: { query, page: 1 } });
  }, [query, page]);

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: "productImage",
        header: "Product Preview",
        accessorKey: "imageUrl",
        cell: ({ getValue }) => {
          const img: any = getValue();
          console.log("____IMG_____", img);
          return (
            <Avatar
              variant="square"
              // src=""
              src={img}
              // width="40px"
              // height="40px"
            />
          );
        }
      },
      {
        id: "productName",
        header: "Product Name",
        accessorKey: "friendlyName",
      },
      {
        id: "mfg",
        header: "Manufacturer",
        accessorKey: "mfg",
      },
      {
        id: "status",
        header: "Status",
        accessorKey: "inventoryStatus.stockStatus",
        // cell: ({ row }) => <InventoryStatusCellRenderer status={row.original.inventoryStatus} />
      },
      {
        id: "price",
        header: "Price",
        accessorKey: "price",
      },
      {
        id: "productClass",
        header: "Product Class",
        accessorKey: "productClass",
      },
    ],
    []
  );

console.log("selectedProducts", {selectedProducts})

  return (
    <>
      <Grid
        container
        rowSpacing={4.5}
        columnSpacing={2.75}
        alignItems="flex-end"
        flexDirection="row"
      >
        <Grid item xs={6}>
          <Typography variant="h4">Product Search</Typography>
        </Grid>
      </Grid>

      <MainCard content={false}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{ padding: 2 }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <DebouncedInput
              value=""
              onFilterChange={(value: any) => handleChangeQuery(String(value))}
              placeholder={`Search CDW For Products`}
            />
            <Button variant="contained" onClick={searchProducts}>
              Search
            </Button>
          </Stack>
        </Stack>

        <DataGrid
          exportable
          selectable
          columns={columns}
          data={products}
          filename="product-table.csv"
    
          mode="virtualization"
          // mode="pagination"
          onFetchMoreData={handleFetchMoreData}
          isLoading={isLoading}
          onRowSelectionChange={handleSelectProduct}
          // pageCount={products.length}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          selectedRowIds={selectedProducts}
          idKey="productCode"
        />
      </MainCard>
      <SelectedProductsDrawer />
    </>
  );
};

export default SearchPage;
