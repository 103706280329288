import { createSlice, createSelector, PayloadAction  } from '@reduxjs/toolkit';
import { AuthState, UserProfile, CDWConfig  } from 'types/auth';


const initialState: AuthState = {
    confirmationStatus: '',
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    companyName: '',
    cdwConfig: null,
};


  
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      loginRequest: (state, action: PayloadAction<{ email: string; password: string; navigate: any }>) => {
        // This action will be caught by a saga
      },
      loginSuccess: (state, action: PayloadAction<{user: UserProfile, config: CDWConfig, companyName: string}>) => {
        const { user, config, companyName } = action.payload;
        state.isLoggedIn = true;
        state.user = user;
        state.cdwConfig = config;
        state.companyName = companyName;
        state.isInitialized = true;
      },

      loginFailure: (state) => {
        state.isLoggedIn = false;
        state.user = null;
        state.isInitialized = true;
      },
      logoutRequest: (state) => {
        // This action will be caught by a saga
      },
      logoutSuccess: (state) => {
        state.isLoggedIn = false;
        state.user = null;
        state.cdwConfig = null;
        state.isInitialized = true;
      },
      logoutFailure: (state) => {
        state.isInitialized = true;
      },
      updateConfirmationStatus: (state, action: PayloadAction<{ confirmationStatus: string; }>) => {
        const { confirmationStatus } = action.payload;
        state.confirmationStatus = confirmationStatus;
      },
      updateUser: (state, action: PayloadAction<{ id: string; firstName: string; lastName: string; email: string; }>) => {
        const { id, firstName, lastName, email } = action.payload;
        state.user = {
          firstName,
          lastName,
          email,
          id
        }
      },
    },
});

export const authPageSlice = createSlice({
  name: 'authPage',
  initialState: {
  },
  reducers: {
    setAuthLoading: (state: any, action: PayloadAction<{ page: "login" | "forceChangePassword", loadingState: "Fulfilled" | "Rejected" | "Pending" }>) => {
      const { page, loadingState } = action.payload;
      state[page] = {
        loading: loadingState
      };
    }
  },
})

export const selectIsLoggedIn = (state: any) => state.auth.isLoggedIn;
export const selectIsInitialized = (state: any) => state.auth.isInitialized;
export const selectCurrentUser = (state: any) => state.auth.user;
  
export const { 
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
  updateConfirmationStatus,
  updateUser,

} = authSlice.actions;


export const { setAuthLoading } = authPageSlice.actions;

export const authSelector = (state: any) => state.auth;

export const authpageSelector = (state: any) => state.pages.authPage;

export const confirmationStatusSelector = createSelector(authSelector, (authState) => {
  return authState?.confirmationStatus;
})

export const currentUserSelector = createSelector(authSelector, (authState) => {
  return authState?.user;
})

export const loginPageSelector = createSelector(authpageSelector, (authPageObj) => {
  return authPageObj?.login;
});

export const isLoginPageLoadingSelector = createSelector(loginPageSelector, (loginPageObj) => {
  const loadingState = loginPageObj?.loading ?? "";
  return loadingState === "Pending";
})

export const loginPageErrorSelector = createSelector(loginPageSelector, (loginPageObj) => {
  const loadingState = loginPageObj?.loading ?? "";
  return loadingState === "Rejected";
})

export const isInitializedSelector = createSelector(authSelector, (authState) => {
  return authState?.isInitialized;
})

export const userFullNameSelector = createSelector(currentUserSelector, (user) => {
  return `${user?.firstName} ${user?.lastName}`;
});
// export const savedOpportunitiesSelector = createSelector(opportunitiesSelector, (opportunitiesPageObj) => {
//   return opportunitiesPageObj?.savedOpportunities;
// })