import { Link } from 'react-router-dom';

// material-ui
import { Grid, Stack, Typography, Alert, AlertTitle } from '@mui/material';

// project import
import useAuth from 'hooks/useAuth';
import { useSelector } from 'store';
import AuthWrapper from 'pages/auth/sections/AuthWrapper';
import AuthLogin from 'pages/auth/sections/AuthLogin';
import AuthForceChangePassword from './sections/AuthForceChangePassword';
import Checkout from './sections/Checkout';
import { confirmationStatusSelector, loginPageErrorSelector } from 'pages/auth/store/authSlice';
import { useMemo } from 'react';

// // ================================|| LOGIN ||================================ //

const Login = () => {
  const confirmationStatus = useSelector(confirmationStatusSelector);
  const hasError = useSelector(loginPageErrorSelector);

  const renderLoginForm = useMemo(() => {
    if (confirmationStatus === 'forceChangePassword') {
      // return <AuthForceChangePassword />;
      return <Checkout />;
    }

    return <AuthLogin />;

    // return <AuthForceChangePassword />;
  }, [confirmationStatus]);

  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center" alignItems="center">
            {confirmationStatus === 'forceChangePassword' ? (
              <Typography variant="h3">Change Password</Typography>
            ) : (
              <Typography variant="h3">Login</Typography>
            )}
          </Stack>
          {hasError && (
            <Alert
              color="error"
              variant="border"
            >
              <AlertTitle>There seems to be an issue with the credentials you provided</AlertTitle>
            </Alert>
          )}
        </Grid>
        <Grid item xs={12}>
          {renderLoginForm}
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default Login;