import React from "react";
import { Grid, Typography } from "@mui/material";
import { InputField, DatePickerField } from "./FormFields";

const CDWConfigForm = (props: any) => {
  const {
    formField: {
        cdwCustomerID,
        cdwTokenKey,
        cdwTokenValue,
        cdwBasicAuthToken,
    },
  } = props;

  return (
    <React.Fragment>

      <Grid container spacing={3}>

        <Grid item xs={12}>
          <InputField
            name={cdwCustomerID.name}
            label={cdwCustomerID.label}
            fullWidth
            // type="password"
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name={cdwTokenKey.name}
            label={cdwTokenKey.label}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name={cdwTokenValue.name}
            label={cdwTokenValue.label}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name={cdwBasicAuthToken.name}
            label={cdwBasicAuthToken.label}
            fullWidth
          />
        </Grid>



      </Grid>
    </React.Fragment>
  );
};

export default CDWConfigForm;
