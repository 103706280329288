import React, { useCallback, useMemo, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import {
  ColumnDef,
  flexRender,
  useReactTable,
  getCoreRowModel,
  Row,
  HeaderGroup,
} from "@tanstack/react-table";
import ScrollX from "components/ScrollX";
import { TableDataProps } from "types/table";
import { LabelKeyObject } from "react-csv/lib/core";
import { useVirtualizer } from "@tanstack/react-virtual";
import { Product } from "../../types/products";

interface ReactTableProps {
  columns: ColumnDef<TableDataProps>[];
  data: TableDataProps[];
  filename?: string;
  exportable?: boolean;
  selectable?: boolean;
  onFetchMoreData?: () => void;
  isLoading?: boolean;
  mode?: "pagination" | "virtualization";
  pageCount?: number;
  onPageChange?: (page: number) => void;
  rowsPerPage?: number;
  onRowsPerPageChange?: (rowsPerPage: number) => void;
  onRowSelectionChange?: (id: Row<Product>) => void;
  onSelectedRowIdsChange?: (selectedRowIds: string[]) => void;
  selectedRowIds?: string[];
  idKey: string;
}


const MemoizedTableRow = React.memo(({ row, selectable, onRowSelectionChange, selectedRowIds, idKey }: { 
  // row: Row<TableDataProps>; 
  row: any;
  selectable: boolean; 
  onRowSelectionChange?: (id: Row<Product>) => void; 
  selectedRowIds?: string[];
  idKey: string;
}) => (
  <TableRow key={row.id}>
    {selectable && (
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedRowIds?.includes(row.original[idKey]) || false}
          onChange={() => onRowSelectionChange && onRowSelectionChange(row)}
        />
      </TableCell>
    )}
    {row.getVisibleCells().map((cell) => (
      <TableCell key={cell.id} sx={{ whiteSpace: "nowrap" }} {...cell.column.columnDef.meta}>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </TableCell>
    ))}
  </TableRow>
));


const DataGrid = ({
  columns,
  data,
  filename = "virtualized-row.csv",
  exportable = true,
  selectable = false,
  onFetchMoreData,
  isLoading = false,
  mode = "virtualization",
  pageCount,
  onPageChange,
  rowsPerPage = 10,
  onRowsPerPageChange,
  onRowSelectionChange,
  onSelectedRowIdsChange,
  selectedRowIds,
  idKey = "id",
}: any) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: table.getRowModel().rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 34,
    overscan: 10,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize();

  const paddingTop = virtualRows.length > 0 ? virtualRows[0].start : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - virtualRows[virtualRows.length - 1].end
      : 0;

  const headers: LabelKeyObject[] = useMemo(
    () =>
      table.getAllColumns().map((column: any) => ({
        label:
          typeof column.columnDef.header === "string"
            ? column.columnDef.header
            : "#",
        key: column.columnDef.accessorKey,
      })),
    [table]
  );

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      // console.log("____handleScroll_____", {e});
      if (e.currentTarget) {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        const bottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
        if (bottom && !isLoading && onFetchMoreData) {
          onFetchMoreData();
        }
      }
    },
    [isLoading]
  );

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      if (onPageChange) {
        onPageChange(newPage);
      }
    },
    [onPageChange]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onRowsPerPageChange) {
        onRowsPerPageChange(parseInt(event.target.value, 10));
      }
    },
    [onRowsPerPageChange]
  );

  return (
    <>
      <TableContainer
        component={Paper}
        ref={tableContainerRef}
        sx={{ height: "700px", overflow: "auto", position: "relative" }}
        onScroll={mode === "virtualization" ? handleScroll : undefined}
      >
        <Table>
          <TableHead className="sticky-header">
            {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
              <TableRow key={headerGroup.id}>
                {selectable && (
                  <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell>
                )}
                {headerGroup.headers.map((header) => (
                  <TableCell key={header.id} {...header.column.columnDef.meta}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody>
            {mode === "virtualization" && paddingTop > 0 && (
              <TableRow>
                <TableCell
                  sx={{ height: `${paddingTop}px`, whiteSpace: "nowrap" }}
                  colSpan={columns.length + (selectable ? 1 : 0)}
                />
              </TableRow>
            )}
            {mode === "virtualization"
              ? virtualRows.map((virtualRow) => {
                console.log("____virtualRow_____", {virtualRow});
                  const row = table.getRowModel().rows[
                    virtualRow.index
                  ] as Row<TableDataProps>;
                  return (
                    <MemoizedTableRow 
                      key={row.id}
                      row={row}
                      selectable={selectable}
                      onRowSelectionChange={onRowSelectionChange}
                      selectedRowIds={selectedRowIds}
                      idKey={idKey}
                    />
                  );
                })
              : table.getRowModel().rows.map((row: any) => (
                  <TableRow key={row.id}>
                    {selectable && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRowIds?.includes(row.original[idKey]) || false}
                          onChange={() => onRowSelectionChange && onRowSelectionChange(row)}
                        />
                      </TableCell>
                    )}
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        sx={{ whiteSpace: "nowrap" }}
                        {...cell.column.columnDef.meta}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            {mode === "virtualization" && paddingBottom > 0 && (
              <TableRow>
                <TableCell
                  sx={{ height: `${paddingBottom}px`, whiteSpace: "nowrap" }}
                  colSpan={columns.length + (selectable ? 1 : 0)}
                />
              </TableRow>
            )}
            {isLoading && (
              <TableRow>
                <TableCell
                  colSpan={columns.length + (selectable ? 1 : 0)}
                  sx={{ textAlign: "center" }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {mode === "pagination" && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={pageCount ?? 0}
          rowsPerPage={rowsPerPage}
          page={pageCount ? Math.floor((data.length - 1) / rowsPerPage) : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default DataGrid;
