import ThemeCustomization from 'themes';
import Routes from 'routes';

function App() { 
  return (
    <ThemeCustomization>
      <Routes />
    </ThemeCustomization>
  )
}

export default App
