// import axios from 'axios';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { schema, normalize } from 'normalizr';
import { 
  setSearchLoading, 
  setError, 
  fetchCDWProductsSuccess, 
  selectedProductsSelector, 
  setUploadLoading, 
  clearEntity,
  clearAllSelectedRows,
  setDrawerOpen,
  setUploadedProducts,
} from "./selectedRowsSlice";
import axios from "utils/axios";

const product = new schema.Entity('products', {}, {
    idAttribute: 'productCode'
  });
  
  const products = [product];

  function* searchCDWProductsSaga(action) {
    try {
      yield put(setSearchLoading({loadingState: "Pending"}))
      yield put(clearEntity())
      
      const { query, page } = action.payload;
      if(query === "") { return }
      
      const response = yield call(axios.get, "/search", {
          params: { 
            keyword: query,
            // pageNum: 194 
            pageNum: page 
            },
  
      });

      let responseData = response.data.products;
      const normalizedData = normalize(responseData, products);
      yield put(fetchCDWProductsSuccess(normalizedData.entities.products as any));
      yield put(setSearchLoading({loadingState: "Fulfilled"}))
    } catch (error: any) {
      console.error("fetchPipelinesSaga error", error)
      yield put(setSearchLoading({loadingState: "Rejected"}));
      yield put(setError({message: error?.message ?? "An error occurred while fetching products"}))
      // throw error;
    }
  }



  function* fetchCDWProductsSaga(action) {
  try {
    yield put(setSearchLoading({loadingState: "Pending"}))
    const { query, page } = action.payload;
    if(query === "") { return }

    const response = yield call(axios.get, "/search", {
        params: { 
          keyword: query,
          // pageNum: 194 
          pageNum: page 
          },

    });
    let responseData = response.data.products;
    // yield call(successCallback, responseData, responseData.length)
    const normalizedData = normalize(responseData, products);
    yield put(fetchCDWProductsSuccess(normalizedData.entities.products as any));
    yield put(setSearchLoading({loadingState: "Fulfilled"}))
  } catch (error: any) {
    console.error("fetchPipelinesSaga error", error)
    yield put(setSearchLoading({loadingState: "Rejected"}));
    yield put(setError({message: error?.message ?? "An error occurred while fetching products"}))
    // throw error;
  }
}


function* uploadCDWProductsSaga(action) {
    try {
      yield put(setUploadLoading({loadingState: "Pending"}))
      const selectedProducts = yield select(selectedProductsSelector);
      console.log("Selected Products", selectedProducts)

      yield delay(2000);

    const response = yield call(axios.post, "/upload", {
        products: selectedProducts,  
    });
    yield put(setUploadedProducts({products: selectedProducts}));
    yield put(setUploadLoading({loadingState: "Fulfilled"}));
    yield put(setDrawerOpen(false));
    yield put(clearAllSelectedRows())
    } catch (error: any) {
      console.error("fetchPipelinesSaga error", error)
      yield put(setSearchLoading({loadingState: "Rejected"}));
      yield put(setError({message: error?.message ?? "An error occurred while uploading products"}))
    }
}


export function* cdwProductSearchWatcher() {
  yield takeLatest("SEARCH_CDW_PRODUCTS", searchCDWProductsSaga);
  yield takeLatest("GET_CDW_PRODUCTS", fetchCDWProductsSaga);
  yield takeLatest("UPLOAD_CDW_PRODUCTS", uploadCDWProductsSaga);
}