import React from "react";
import { Grid } from "@mui/material";
import { InputField } from "./FormFields";

const UserDetailsForm = (props: any) => {
  const {
    formField: { firstName, lastName, email, company },
  } = props;
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <InputField name={firstName.name} label={firstName.label} fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <InputField
            name={lastName.name}
            label={lastName.label}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name={email.name}
            label={email.label}
            fullWidth
            required
            type="email"
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name={company.name}
            label={company.label}
            fullWidth
            required
            placeholder="Demo Inc."
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default UserDetailsForm;
