import React from "react";
import { Grid, Typography } from "@mui/material";
import { InputField, DatePickerField } from "./FormFields";

const PasswordForm = (props: any) => {
  const {
    formField: { password, confirmPassword },
  } = props;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment method
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputField
            name={password.name}
            label={password.label}
            fullWidth
            type="password"
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            name={confirmPassword.name}
            label={confirmPassword.label}
            fullWidth
            type="password"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PasswordForm;
