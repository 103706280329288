import React from "react";
import PropTypes from "prop-types";
import { at } from "lodash";
import { useField } from "formik";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
  OutlinedInput,
  Stack,
  //   MuiPickersUtilsProvider,
  //   KeyboardDatePicker
} from "@mui/material";

// import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@mui/x-date-pickers';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export function DatePickerField(props) {
  const [field, meta, helper] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const { value } = field;
  // const [selectedDate, setSelectedDate] = useState(null);

  // useEffect(() => {
  //   if (value) {
  //     const date = new Date(value);
  //     setSelectedDate(date);
  //   }
  // }, [value]);

  // function _onChange(date) {
  //   if (date) {
  //     setSelectedDate(date);
  //     try {
  //       const ISODateString = date.toISOString();
  //       setValue(ISODateString);
  //     } catch (error) {
  //       setValue(date);
  //     }
  //   } else {
  //     setValue(date);
  //   }
  // }

  return (
    <Grid container>
      <div>Yagag</div>
    </Grid>
  );
}

export const InputField = (props) => {
  console.log("InputField props", props);
  const { errorText, label, name, ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return error;
    }
  }

  return (
    <Stack spacing={1}>
      <InputLabel htmlFor={label}>{label}</InputLabel>
      <OutlinedInput
        type="text"
        error={meta.touched && meta.error && true}
        {...field}
        {...rest}
      />
      {meta.touched && meta.error && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
    </Stack>
  );
}

export function CheckboxField(props) {
  const { label, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  function _onChange(e) {
    setValue(e.target.checked);
  }

  return (
    <FormControl {...rest}>
      <FormControlLabel
        value={field.checked}
        checked={field.checked}
        control={<Checkbox {...field} onChange={_onChange} />}
        label={label}
      />
      {_renderHelperText()}
    </FormControl>
  );
}

export function SelectField(props) {
  const { label, data, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  return (
    <FormControl {...rest} error={isError}>
      <InputLabel>{label}</InputLabel>
      <Select {...field} value={selectedValue ? selectedValue : ""}>
        {data.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

SelectField.defaultProps = {
  data: [],
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SelectField;
