import { Outlet } from "react-router-dom";
import { MainLayoutContainer } from "../MainLayoutContainer";
import { Box, Container } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";

const ProfileSetupLayout = () => {
  return (
    <MainLayoutContainer>
      <Header />

      <Box
        component="main"
        className="main"
        sx={{
          width: "100%",
          overflow: "hidden",
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
        }}
      >
        <Container
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "flex-end",
          }}
        >
          <Outlet />
        </Container>
      </Box>

      <Footer />
    </MainLayoutContainer>
  );
};

export default ProfileSetupLayout;
