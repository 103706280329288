import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import userPool from 'utils/userpools';
// import { UserData } from 'types/authTypes';

/**
 * Signs out the current user from the Cognito session.
 */
export const signOutUser = (): void => {
  const currentUser = userPool.getCurrentUser();
  if (currentUser) {
    currentUser.signOut();
  }
};



export const formatCognitoUser = (user: any) => {
  const { UserAttributes, Username, } = user;
  const formattedAttributes = UserAttributes.reduce((acc: any, attr: any) => {
    acc[attr.Name] = attr.Value;
    return acc;
  }, {});
  const { email, family_name: lastName, given_name: firstName, sub: id } = formattedAttributes;

  return {
    username: Username,
    email,
    lastName,
    firstName,
    id
  };
}




/**
 * Retrieves the current session for the logged-in user.
 * @returns A promise that resolves with the current session if the user is logged in.
 */
export const getCurrentUserSession = (): Promise<any> => {
  const currentUser = userPool.getCurrentUser();
  if (!currentUser) {
    return Promise.reject(new Error('No current user'));
  }

  return new Promise((resolve, reject) => {
    currentUser.getSession((err, session) => {
      if (err || !session.isValid()) {
        reject(err || new Error('Invalid session'));
      } else {
        resolve(session);
      }
    });
  });
};



export const getUserData = (currentUser: CognitoUser): Promise<any> => {
  return new Promise((resolve, reject) => {
    currentUser.getUserData((err, userData) => err ? reject(err) : resolve(userData as any));
  });
};

/**
 * Gets the user data for the currently logged-in user.
 * @returns A promise that resolves with user attributes if available.
 */
export const getCurrentUserData = (currentUser): Promise<any> => {
  // const currentUser = userPool.getCurrentUser();
  if (!currentUser) {
    return Promise.reject(new Error('No current user'));
  }

  return new Promise((resolve, reject) => {
    currentUser.getUserData((err, userData) => {
      if (err) {
        reject(err);
      } else {
        resolve(userData);
      }
    });
  });
};

export const getCurrentUser = (): Promise<any> => {
  const currentUser = userPool.getCurrentUser();
  if (!currentUser) {
    // return Promise.reject(new Error('No current user'));
    return Promise.resolve(null);
  }
  return Promise.resolve(currentUser);
};

export const getCurrentSession = (currentUser: CognitoUser): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    currentUser.getSession((err, session) => err ? reject(err) : resolve(session));
  });
};


export const newPassword = async (user: CognitoUser | null, newPassword: string, userAttributes = {}) => {
  return new Promise((resolve, reject) => {
    if (user) {
      user.completeNewPasswordChallenge(newPassword, userAttributes, {
        onSuccess: (session) => {
          console.log('Password changed successfully', session);
          resolve(session);
        },
        onFailure: (err) => {
          console.error('Failed to change password', err);
          reject(err);
        }
      });
    }
  });
};


function getUserToken(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getSession(function(err, session) {
      if (err) {
        reject(err);
        return;
      }
      resolve(session.getIdToken().getJwtToken());
    });
  });
}

export async function authUser() {
  const currentUser = await getCurrentUser();

  if (currentUser === null) {
    return false;
  }

  const token = await getUserToken(currentUser);
  console.log('Token: ', token);

  return true;
}