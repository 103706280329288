import Profile from './Profile';
import Cart from './Cart';

const HeaderContent = () => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    }}>
      <Cart />
      <Profile />
    </div>
  );
};

export default HeaderContent;
