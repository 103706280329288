import { lazy } from 'react';

import { Navigate } from 'react-router-dom';
import MainLayout from 'components/layout/MainLayout';
import CommonLayout from 'components/layout/CommonLayout';
import ProfileLayout from 'components/layout/ProfileSetupLayout';
import Loadable from 'components/Loadable';

import AuthLogin  from 'pages/auth/login';
import { ProfileSetupPage } from 'pages/auth/profile-setup';

// render - login
// const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));
// const ProfileSetup = Loadable(lazy(() => import('pages/auth/profile-setup')));


// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <CommonLayout />,
      children: [
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'register',
          element: <AuthRegister />
        },
        {
          path: 'forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: 'check-mail',
          element: <AuthCheckMail />
        },
        {
          path: 'reset-password',
          element: <AuthResetPassword />
        },
        {
          path: 'code-verification',
          element: <AuthCodeVerification />
        }
      ]
    },
    {
      path: 'profile-setup/*',
      element: <ProfileLayout />, // Use ProfileLayout for profile setup routes
      children: [
        {
          path: 'basic',
          element: <ProfileSetupPage />
        },
        // {
        //   path: 'location',
        //   element: <ProfileSetup />
        // },
        // {
        //   path: 'submit',
        //   element: <ProfileSetup />
        // }
      ]
    }
  ]
};

export default LoginRoutes;
