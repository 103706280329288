import axios from 'axios';
// import userPool from 'utils/userpools';
// import { getCurrentUserSession } from 'utils/cognitoHelpers';


const axiosInstance = axios.create({ 
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3001/api'
});

// axiosInstance.interceptors.request.use(async (config) => {
//   const currentUserSession = await getCurrentUserSession();
//   const jwtToken = currentUserSession.getIdToken().getJwtToken();
//   const token = jwtToken.replace(/\n/g, '').trim();
//   // const jwtToken = currentUserSession.getIdToken().getJwtToken().replace(/\n/g, '');
//   // console.log('JWT Token', token);
//   config.headers.Authorization = jwtToken;
//   return config;
// }, (error) => {
//   return Promise.reject(error);
// });

export default axiosInstance;
