import { useRoutes } from 'react-router-dom';
import MainRoutes from 'pages/search/routes/SearchRoutes';
// import DashboardRoutes from 'pages/dashboard/routes/DashboardRoutes';
import LoginRoutes from 'pages/auth/routes/LoginRoutes';
// import ProfileRoutes from 'pages/auth/routes/ProfileRoutes';
// import ImageUploaderRoutes from 'pages/image-uploader/routes/ImageUploaderRoutes';

// ==============================|| ROUTING RENDER ||============================== //


// const baseName = import.meta.env.VITE_APP_BASE_NAME || "" ; 

export default function ThemeRoutes() {

  return( useRoutes([
      MainRoutes,
      LoginRoutes,
    ])
  
  )
  

}
