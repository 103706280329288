// material-ui
import { useTheme } from '@mui/material/styles';
import { ThemeMode } from 'types/config';
import logoDark from 'assets/images/Mocho.png';
import logo from 'assets/images/Mocho.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="Mantis" width="100" />
     *
     */
    <>
    <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="Mocho Solutions" width="100" />
    </>
  );
};

export default LogoMain;
