import React from "react";
import {
  Routes,
  Route,
  Link as RouterLink,
  useNavigate,
  Navigate,
} from "react-router-dom";
import * as Yup from "yup";
import { Grid, Button } from "@mui/material";
import AuthWrapper from "pages/auth/sections/AuthWrapper";
import { useDispatch } from "react-redux";
import useAuth from "hooks/useAuth";
import { Link } from "react-router-dom";
import AuthLogin from "pages/auth/sections/AuthLogin";
import AuthCard from "./sections/AuthCard";
import CheckoutPage from "./sections/Checkout";

interface FormValues {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
}

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zipCode: Yup.string().required("Zip Code is required"),
});

interface StepProps {
  values: FormValues;
  handleBlur: (e: React.FocusEvent<any>) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  touched: {
    [field: string]: boolean;
  };
  errors: {
    [field: string]: string | undefined;
  };
}

interface SubmitPageProps {
  handleSubmit: () => void;
}

export const SubmitPage: React.FC<SubmitPageProps> = ({ handleSubmit }) => (
  <Button onClick={handleSubmit} variant="contained" fullWidth>
    Submit
  </Button>
);

export const ProfileSetupPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn } = useAuth();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        className="slime"
      >
        

        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            // sx={{ minHeight: { xs: 'calc(100vh - 210px)', sm: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}
          >
        
            <Grid item>
              {/* <AuthCard> */}
                {/* <AuthLogin /> */}
                <CheckoutPage />
              {/* </AuthCard> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileSetupPage;
