// third-party
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { useDispatch as useAppDispatch, useSelector as useAppSelector, TypedUseSelectorHook } from 'react-redux';

// project import
import reducers from './reducers';
import rootSaga  from './sagas/rootsaga';
// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  const middleware = [
    sagaMiddleware, 
    // logger
  ];

  const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  })
  sagaMiddleware.run(rootSaga)

  return store;
}


const store = createStore();

// export type RootState = ReturnType<typeof reducers>;

// export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();
const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export { store, dispatch, useSelector, useDispatch };
