import { call, delay, put, take, takeLatest } from 'redux-saga/effects';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import userPool, { newPassword } from 'utils/userpools';
import { get } from "lodash";
import {
  formatCognitoUser,
  getCurrentSession,
  getCurrentUserSession,
  getCurrentUser,
  getUserData,
  authUser
} from 'utils/cognitoHelpers';
import {
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
  updateConfirmationStatus,
  updateUser,
  setAuthLoading
} from './authSlice';
import axiosPrivate from 'utils/axios';


const authenticate = ({ email, password }: { email: string; password: string; }) => {
  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (session) => {
        console.log('Session Success...')
        resolve({ session, user: cognitoUser })
      },
      onFailure: (err) => {
        console.log('Session Failure...')
        reject(err)
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        // consol
        console.log('New password required', { userAttributes, requiredAttributes });
        resolve({ session: null, user: cognitoUser, attributes: { userAttributes, requiredAttributes }, confirmationStatus: "forceChangePassword" })
      }
      // Handle other cases like newPasswordRequired as needed
    });
  });
}


// 8-character minimum length
// Contains at least 1 number
// Contains at least 1 lowercase letter
// Contains at least 1 uppercase letter
// Contains at least 1 special character from the following set, or a non-leading, non-trailing space character.
// ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ ` + =

function* handleLogin(action: ReturnType<typeof loginRequest>) {
  try {
    const { email, password, navigate } = action.payload;
    yield put(setAuthLoading({ page: "login", loadingState: "Pending" }))
    const authResp = yield call(authenticate, { email, password });
    const { session, user, confirmationStatus, attributes } = authResp;

    console.log('AuthResp', { session, user, confirmationStatus, attributes });

    if (confirmationStatus === "forceChangePassword") {
      console.log('ForceChange', { confirmationStatus, user, session, attributes });
      yield put(updateConfirmationStatus({ confirmationStatus }));
      // 
      yield put(updateUser({
        id: user?.username,
        email: attributes.userAttributes.email,
        firstName: attributes.userAttributes.given_name,
        lastName: attributes.userAttributes.family_name

      }))

      const successAction = yield take(action => action.type === 'FORCE_CHANGE_PASSWORD_REQUEST');

      console.log('SuccessAction', successAction)
      const {
        firstName,
        lastName,
        email,
        password,
        company,
        cdwTokenKey,
        cdwTokenValue,
        cdwBasicAuthToken,
        cdwCustomerID,

      } = successAction.payload;


      const pwdResponse = yield newPassword(user, password,
        {
          "given_name": firstName,
          "family_name": lastName,
          "custom:company_name": company,
          "custom:cdw_token_key": cdwTokenKey,
          "custom:cdw_token_value": cdwTokenValue,
          "custom:cdw_customer_id": cdwCustomerID,
          "custom:cdw_basic_auth_token": cdwBasicAuthToken,
        });

      yield put(loginSuccess({
        companyName: company,
        user: {
          email,
          lastName,
          firstName,
          id: user.username,
        },
        config: {
          cdwTokenKey,
          cdwTokenValue, 
          cdwCustomerID, 
          cdwBasicAuthToken
        }
        // email,
        // lastName,
        // firstName,
        // id: user.username,

      }));

      yield call(navigate, '/')
      return;
    }


    if (user) {
      console.log("User LOGIN", {user, attributes, authResp});
      const userData = yield call(getUserData, user);
      const formattedUserData = yield call(formatCognitoUser, userData);

      const sessionPayload = get(user, ["signInUserSession", "idToken", "payload"], {});
      console.log('SessionPayload', sessionPayload)

      const companyName = get(sessionPayload, "custom:company_name", "");
      const cdwTokenKey = get(sessionPayload, "custom:cdw_token_key", "");
      const cdwTokenValue = get(sessionPayload, "custom:cdw_token_value", "");
      const cdwCustomerID = get(sessionPayload, "custom:cdw_customer_id", "");
      const cdwBasicAuthToken = get(sessionPayload, "custom:cdw_basic_auth_token", "");

      const config = {
        cdwTokenKey,
        cdwTokenValue,
        cdwCustomerID,
        cdwBasicAuthToken,
      }

      console.log('FormattedUserData', formattedUserData)
      console.log('Config', config)

      yield put(loginSuccess({user: formattedUserData, config, companyName}));
      yield put(setAuthLoading({ page: "login", loadingState: "Fulfilled" }))
      yield call(navigate, '/')
    } else {
      console.error("An error occurred while trying to get user data")
    }
  } catch (error) {
    yield put(setAuthLoading({ page: "login", loadingState: "Rejected" }))
    yield put(loginFailure());
  }
}

function* handleLogout(action) {
  try {
    console.log('handleLogout', action.payload)
    const { navigate } = action.payload;
    // Add logic for Cognito logout if necessary
    const user = userPool.getCurrentUser();
    if (user) {
      user.signOut();
    }
    yield call(navigate, '/login')
    yield put(logoutSuccess());
    // Navigate or clean up resources as needed
  } catch (error) {
    yield put(logoutFailure());
  }
}

function* handleInitalization(action) {
  try {
    const { navigate } = action.payload;
    const currentUser = yield getCurrentUser();

    console.log('handleInitalization   SAGA....', currentUser)
    // const currentUser = yield getCurrentUserSession();
    // const currentUser = userPool.getCurrentUser();
    // const session = yield getCurrentSession(currentUser);
    // console.log('handleInitalization', currentUser)

    if (currentUser) {
      try {
        const session = yield getCurrentSession(currentUser);
        console.log('session', session)
        if (session.isValid()) {
          // console.log('Session is valid');
          const userData = yield call(getUserData, currentUser);
          const formattedUserData = yield call(formatCognitoUser, userData);
          console.log("formattedUserData", formattedUserData)
          yield put(loginSuccess(formattedUserData));
        } else {
          yield call(navigate, '/login')
        }

      } catch (sessionError) {
        console.error('Session is not valid', sessionError);
        // yield handleLogout({ navigate});
      }
    } else {
      yield call(navigate, '/login')
    }
  } catch (err) {
    console.error('Error initializing app', err)
  }
}

export function* authWatcher() {
  yield takeLatest("INIT", handleInitalization);
  yield takeLatest(loginRequest.type, handleLogin);
  yield takeLatest("LOGOUT", handleLogout);
}