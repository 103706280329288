import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

const poolData = {
    UserPoolId: process.env.REACT_APP_AWS_POOL_ID || '',
    ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID || '',
};


const userpool = new CognitoUserPool(poolData);

export const newPassword = async (user: any, newPassword: any, userAttributes = {}) => {
    return new Promise((resolve, reject) => {
        user.completeNewPasswordChallenge(newPassword, userAttributes, {
            onSuccess: (session: any) => {
                console.log("Password changed successfully", session);
                resolve(session);
            },
            onFailure: (err: any) => {
                console.error("Failed to change password", err);
                reject(err);
            }
        });
    });
};


export default userpool;