import React from "react";
import HomePage from "../index"
import { useRouteError, Outlet } from "react-router-dom";
import MainLayout from 'components/layout/MainLayout';

function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

const MainRoutes = {
  breadcrumbs: false,
  path: "/",
  element: <MainLayout />,
  errorElement: <ErrorPage />,
  children: [
    {
      path: '/',
      element: (
            <HomePage />
      )
    }
  ]
};

export default MainRoutes;