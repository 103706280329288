import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
  Grid,
} from '@mui/material';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { currentUserSelector } from 'pages/auth/store/authSlice';
import { useSelector, useDispatch } from 'store';
import UserDetailsForm from './UserDetailsForm';
import PasswordForm from './PasswordForm';
import CDWConfigForm from './CDWConfigForm';
import ReviewOrder from './Revieworder';

import CheckoutSuccess from './CheckoutSuccess';
import { checkoutFormModel } from './constants';

const {
    formField: {
        firstName,
        lastName,
        email,
        company,
        password,
        confirmPassword,
        cdwCustomerID,
        cdwTokenKey,
        cdwTokenValue,
        cdwBasicAuthToken,
    }
  } = checkoutFormModel;
  
  const validationSchema = [
    Yup.object().shape({
      [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
      [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
      [email.name]: Yup.string().required(`${email.requiredErrorMsg}`),
      [company.name]: Yup.string().required(`${company.requiredErrorMsg}`),
    }),

    Yup.object().shape({
      [password.name]: Yup.string().required(`${password.requiredErrorMsg}`).min(8),
      [confirmPassword.name]: Yup.string().required(`${confirmPassword.requiredErrorMsg}`).oneOf([Yup.ref(password.name)], 'Passwords must match'),
    }),

    Yup.object().shape({
      [cdwCustomerID.name]: Yup.string().required(`${cdwCustomerID.requiredErrorMsg}`),
      [cdwTokenKey.name]: Yup.string().required(`${cdwTokenKey.requiredErrorMsg}`),
      [cdwTokenValue.name]: Yup.string().required(`${cdwTokenValue.requiredErrorMsg}`),
      [cdwBasicAuthToken.name]: Yup.string().required(`${cdwBasicAuthToken.requiredErrorMsg}`),
    })
  ];


const steps = ['User Details', 'Change Password', 'API Config', 'Confirm'];
const { formId, formField } = checkoutFormModel;

function _renderStepContent(step: number, user: any) {
  switch (step) {
    case 0:
      return <UserDetailsForm formField={formField}  user={user}/>;
    case 1:
      return <PasswordForm formField={formField} />;
    case 2:
        return <CDWConfigForm formField={formField} />;
    case 3:
      return <ReviewOrder />;
    default:
      return <div>Not Found</div>;
  }
}


export default function CheckoutPage() {
//   const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const dispatch = useDispatch();
  const user = useSelector(currentUserSelector);
  const navigate = useNavigate();

  console.log({user});

  function _sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values: any, actions: any) {
    // await _sleep(1000);
    // alert(JSON.stringify(values, null, 2));
    actions.setSubmitting(false);

    dispatch({
      type: 'FORCE_CHANGE_PASSWORD_REQUEST',
      payload: {
        ...values,
        navigate: navigate
      }
    });

    // setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values: any, actions: any) {
    // console.log("handleSubmit", {values, actions});
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <React.Fragment>
      <Stepper activeStep={activeStep} sx={{marginBottom: 2}}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>


      <React.Fragment>
        {activeStep === steps.length ? (
          <CheckoutSuccess />
        ) : (
          <Formik
          initialValues={{
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            email: user?.email || '',
            password: '',
            confirmPassword: '',
            cdwCustomerID: '',
            cdwTokenKey: '',
            cdwTokenValue: '',
            cdwBasicAuthToken: '',
          }}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep, user)}

                <div 
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    // border: '1px solid black',
                    marginTop: '16px',
                  }}
                >
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack}>
                      Back
                    </Button>
                  )}
                  <div>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary">
                      {isLastStep ? 'Place order' : 'Next'}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress size={24} />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
