import {all} from "redux-saga/effects";
import { cdwProductSearchWatcher } from "pages/search/redux/searchSaga";
import { authWatcher } from "pages/auth/store/authSaga";

function* rootSaga(){
    yield all([
        cdwProductSearchWatcher(),
        authWatcher(),
    ])
}


export default rootSaga;