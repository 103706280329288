import { Grid, Typography } from "@mui/material";
import { useFormikContext } from 'formik';

export const ReviewOrder = () => {
    const { values: formValues } = useFormikContext();

    return (
      <>
        <Typography variant="h6" gutterBottom>
          Order summary
        </Typography>
        <Grid container spacing={2}>
            Review Order
        </Grid>
      </>
    );
  }

export default ReviewOrder;