import styled from 'styled-components';

export const MainLayoutContainer= styled.div`
    height: 100%;
    width: 100%;
    display: grid; 
    grid-template-columns: auto 1fr auto;
    grid-template-rows   : auto 1fr auto;
    grid-template-areas: 
        "header header header" 
        "main main main" 
        "footer footer footer";
    overflow: auto;
`