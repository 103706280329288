import { createSlice, createSelector, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { Product } from '../../../types/products';
import { RootState } from '../../../store';
import { get, remove } from 'lodash';


interface SelectedRowsState {
  query: string;
  // selectedRows: string[];
  // products: Product[];
  savedProducts: {
    [id: string]: Product
  };
  error: null | { message: string, hasError: boolean };
  loading: {
    search: string;
    upload: string;
  };
  selectedRowIds: string[];
  uploadedProducts: {
    [id: string]: Product
  };
  sorting?: { id: string; desc: boolean }[];
  filters?: { id: string; value: any }[];
  columnOrder?: string[];
  columnResizing?: { [key: string]: number };
  drawerOpen: boolean;
}

const cdwProductsAdapter = createEntityAdapter({
  selectId: (product: any) => product.productCode,
});

export const cdwProductsEntitySlice = createSlice({
  name: 'cdwProducts',
  initialState: cdwProductsAdapter.getInitialState(),
  reducers: {
    fetchCDWProductsSuccess: (state, action: any) => {
      console.log("fetchCDWProductsSuccess Action", action)
      cdwProductsAdapter.upsertMany(state, action.payload);
    },

    clearEntity: (state,) => {
      // state.query = action.payload.query;
      cdwProductsAdapter.removeAll(state);
    },
  },
})

export const { selectEntities: selectCDWProductsEntities, selectAll: selectAllCDWProductsSelector, selectById: selectCDWProductsById } = cdwProductsAdapter.getSelectors((state: any) => (state.entities.cdwProducts))
export const { fetchCDWProductsSuccess, clearEntity } = cdwProductsEntitySlice.actions;


const initialState: SelectedRowsState = {
  query: "",
  // selectedRows: [],
  // products: [],
  error: null,
  loading: {
    search: "",
    upload: "",
  },
  selectedRowIds: [],
  savedProducts: {},
  uploadedProducts: {},
  sorting: [],
  filters: [],
  columnOrder: [],
  columnResizing: {},
  drawerOpen: false,
};

const selectedRowsSlice = createSlice({
  name: 'selectedRows',
  initialState,
  reducers: {
    updateQuery: (state, action: PayloadAction<{ query: string }>) => {
      state.query = action.payload.query;
    },

    // addRowSelection: (state, action: PayloadAction<{product: Product}>) => {
    //   const product = action.payload.product;
    //   const productCode = action.payload.product.productCode ?? "";
    //   state.selectedRowIds.push(productCode);
    //   state.savedProducts[productCode] = product;
    // }
    // ,
    // unselectRow: (state, action: PayloadAction<string>) => {
    //   const productId = action.payload;
    //   delete state.savedProducts[productId];
    //   state.selectedRowIds = state.selectedRowIds.filter(id => id !== productId);
    //   // state.savedProducts = state.savedProducts.filter(p => p.productCode !== action.payload);
    // },

    toggleRowSelection: (state, action: PayloadAction<{ product: Product, toggle: boolean; }>) => {
      console.log("Yagag...")
      const product = action.payload.product;
      const productCode = action.payload.product.productCode ?? "";
      if (!action.payload.toggle) {
        state.selectedRowIds.push(productCode);
        state.savedProducts[productCode] = product;
      } else {
        delete state.savedProducts[productCode];
        state.selectedRowIds = state.selectedRowIds.filter(id => id !== productCode);
      }
    },

    removeRowSelection: (state, action: PayloadAction<{ id: string }>) => {
      const productId = action.payload.id;
      delete state.savedProducts[productId];
      state.selectedRowIds = state.selectedRowIds.filter(id => id !== productId);
    },

    clearAllSelectedRows: (state) => {
      state.savedProducts = {};
      state.selectedRowIds = [];
    },

    setUploadedProducts: (state, action: PayloadAction<{ products: Product[] }>) => {
      const products = action.payload.products;
      products.forEach((product) => {
        state.uploadedProducts[product.productCode] = product;
      })
    },

    setSearchLoading: (state: any, action: PayloadAction<{ loadingState: "Fulfilled" | "Rejected" | "Pending" }>) => {
      state.loading.search = action.payload.loadingState;
    },
    setError: (state: any, action: PayloadAction<{ message: string }>) => {
      state.error = { message: action.payload.message, hasError: true };
    },

    setUploadLoading: (state: any, action: PayloadAction<{ loadingState: "Fulfilled" | "Rejected" | "Pending" }>) => {
      state.loading.upload = action.payload.loadingState;
    },

    setSorting(state, action: PayloadAction<{ id: string; desc: boolean }[]>) {
      state.sorting = action.payload;
    },
    setFilters(state, action: PayloadAction<{ id: string; value: any }[]>) {
      state.filters = action.payload;
    },
    setColumnOrder(state, action: PayloadAction<string[]>) {
      state.columnOrder = action.payload;
    },
    setColumnResizing(state, action: PayloadAction<{ [key: string]: number }>) {
      state.columnResizing = action.payload;
    },

    setDrawerOpen(state, action: PayloadAction<boolean>) {
      state.drawerOpen = action.payload;
    }
  },
});


export const {
  // selectRow,
  // unselectRow,
  updateQuery,
  toggleRowSelection,
  removeRowSelection,
  setSearchLoading,
  setError,
  setSorting,
  setFilters,
  setColumnOrder,
  setColumnResizing,
  setDrawerOpen,
  setUploadLoading,
  clearAllSelectedRows,
  setUploadedProducts

} = selectedRowsSlice.actions;

export const cdwPageSelector = (state: RootState) => state.pages.cdwPage;

export const selectedRowsSelector = createSelector(cdwPageSelector, (pageObj) => {
  return pageObj?.selectedRowIds || [];
});

export const querySelector = createSelector(cdwPageSelector, (pageObj) => {
  return pageObj?.query || "";
});

export const formattedProductsSelector = createSelector(selectAllCDWProductsSelector, (products) => {
  return products
})

export const savedProductsObjSelector = createSelector(cdwPageSelector, (pageObj) => {
  return pageObj?.savedProducts;
})


// export const savedProductsListSelector = createSelector(formattedProductsSelector, savedProductsObjSelector)


export const selectedProductsSelector = createSelector(selectedRowsSelector, selectCDWProductsEntities, savedProductsObjSelector, (selectedProducts, productEntityObj, savedProductsObj) => {
  // const productEntity = get(productEntityObj, [id], {});
  return selectedProducts.map((id: string) => {
    const entity = productEntityObj[id] ?? {};
    const savedProduct = savedProductsObj[id] ?? {};
    return {
      ...savedProduct,
      // ...entity,
    }
  });
});


export const selectedProductCountSelector = createSelector(selectedRowsSelector, (selectedRows) => {
  return selectedRows.length ?? 0;
})

export const isSearchPageLoadingSelector = createSelector(cdwPageSelector, (pageObj) => {
  return pageObj?.loading?.search === "Pending";
});

export const drawerOpenSelector = createSelector(cdwPageSelector, (pageObj) => {
  return pageObj?.drawerOpen;
})

export const isProductUploadLoadingSelector = createSelector(cdwPageSelector, (pageObj) => {
  return pageObj?.loading?.upload === "Pending";
});

export default selectedRowsSlice.reducer;