import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Divider,
  Button,
  Checkbox,
} from "@mui/material";

import {
  selectedProductCountSelector,
  selectedProductsSelector,
  drawerOpenSelector,
  setDrawerOpen,
  isProductUploadLoadingSelector,
  removeRowSelection,
} from "../redux/selectedRowsSlice";
import {
  CloseCircleOutlined,
  CloseOutlined,
  UploadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
// import SimpleBarScroll from "components/third-party/SimpleBar";
import { useMemo } from "react";

const SelectedProductsDrawer = () => {
  const dispatch = useDispatch();
  const selectedProducts = useSelector(selectedProductsSelector);
  const open = useSelector(drawerOpenSelector);
  const selectedProductCount = useSelector(selectedProductCountSelector);
  const isLoading = useSelector(isProductUploadLoadingSelector);

  // console.log("Selected Products Laoding", isLoading);

  const handleClose = () => {
    dispatch(setDrawerOpen(false));
  };

  const handleUpload = () => {
    dispatch({ type: "UPLOAD_CDW_PRODUCTS" });
  };

  const createRemoveProductHandler = (id: string) => () => {
    dispatch(removeRowSelection({id}));
  };


  const isProductsUplaoding = useMemo(() => {
    if (isLoading) {
      return true;
    }else if (selectedProductCount <= 0) {
      return true;
    }
    return false;

  }, [isLoading, selectedProductCount])


  return (
    <Drawer open={open} anchor="right" onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "300px",
          // minWidth: "280px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "74px",
            marginLeft: "16px",
            marginRight: "16px",
          }}
        >
          <Typography variant="h4">{selectedProductCount} Items</Typography>
          <IconButton onClick={handleClose}>
            <CloseCircleOutlined />
          </IconButton>
        </Box>

        <Divider />

        <List
          sx={{
            flexGrow: 1,
            overflowY: "auto",
          }}
        >
          {selectedProducts.map((obj: any) => {
            console.log("Object", obj);
            return (
              <>
                <ListItem
                  alignItems="flex-start"
                  secondaryAction={
                    <IconButton aria-label="comment" onClick={createRemoveProductHandler(obj.productCode)}>
                      <CloseOutlined />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar src={obj.imageUrl ?? ""} />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{
                      marginRight: "1rem",
                    }}
                    primary={obj.friendlyName}
                    secondary={obj.mfg}
                  />
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "16px",
            marginRight: "16px",
            marginBottom: "16px",
            borderTop: "1px solid #f0f0f0",
          }}
        >
          <Divider />

          <Button
            fullWidth
            variant="contained"
            onClick={handleUpload}
            startIcon={isLoading ? <LoadingOutlined />  : <UploadOutlined /> }
            disabled={isProductsUplaoding}
          >
            Upload Items
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SelectedProductsDrawer;
